import React from "react";
import Users from "../components/Users";

const UsersPage = () => {
  return (
    <div>
      <Users />
    </div>
  );
};

export default UsersPage;
