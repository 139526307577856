import React from "react";
import Videos from "../components/Videos";

const VideosPage = () => {
  return (
    <div>
      <Videos />
    </div>
  );
};

export default VideosPage;
